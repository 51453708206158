
































































































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref, Watch } from "vue-property-decorator";

import { ProjectInviteDto, OrganizationUnitDto } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import OrganizationSelect from "@/components/OrganizationSelect/index.vue";
import CreateProjectInvite from "@/views/project/projectInvite/edit.vue";
import ProjectInviteDetail from "@/views/project/projectInvite/detail.vue";

@Component({
  name: "RoleList",
  components: {
    PagedTableView,
    OrganizationSelect,
    CreateProjectInvite,
    ProjectInviteDetail,
  },
})
export default class ProjectInviteList extends Vue {
  @Ref() readonly pagedTableView!: any;
  @Ref() editForm!: CreateProjectInvite;
  tableItems: ProjectInviteDto[] = [];
  type = false; // true: 内部项目 false: 外部项目
  editFormId = 0;
  private currentDetail: ProjectInviteDto = {};

  queryForm = {
    displayName: "",
    year: "",
    isInternal: false,
  };

  @Watch("$route.params")
  changeRoute(newValue: any) {
    this.queryForm.isInternal = this.type =
      this.$route.params.type === "internal";
    this.pagedTableView!.fetchData();
  }

  created() {
    this.queryForm.isInternal = this.type =
      this.$route.params.type === "internal";
  }

  // 获取表数据
  fetchData(params: any) {
    params.isInternal = this.type = this.$route.params.type === "internal";
    return api.projectInvite.getAll(params);
  }

  // 新建
  handleCreate() {
    this.editFormId = 0;
    (this.$refs.editForm as any).show = true;
  }

  handleMenu(e: any) {
    switch (e.type) {
      case "detail": {
        this.jumpDetail(e.index, e.item);
        return;
      }
      case "delete": {
        this.handleDelete(e.index, e.item);
        return;
      }
      case "edit": {
        this.handleEdit(e.index, e.item);
        return;
      }
      case "editCustomForm": {
        this.handleEditCustomForm(e.index, e.item);
        return;
      }
      case "copy": {
        this.handleCopy(e.index, e.item);
        return;
      }
    }
  }

  // 查看详情
  jumpDetail(index: number, row: ProjectInviteDto) {
    this.currentDetail = row;
    (this.$refs.projectInviteDetail as any).show = true;
  }

  // 删除
  async handleDelete(index: number, row: ProjectInviteDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.projectInvite.delete({ id: row.id }).then((res) => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    });
  }

  // 编辑
  async handleEdit(index: number, row: ProjectInviteDto) {
    this.editFormId = Number(row.id);
    (this.$refs.editForm as any).show = true;
  }

  // 编辑自定义表单
  handleEditCustomForm(index: number, row: ProjectInviteDto) {
    if (row.project && row.project.id) {
      this.$router.push({
        name: "projectCustomForm",
        query: {
          id: row.project.id.toString(),
        },
      });
    }
  }

  // 复制内部项目
  handleCopy(index: number, row: ProjectInviteDto) {
    api.internalProject
      .copy({ projectId: row.project?.id })
      .then((res: ProjectInviteDto) => {
        this.$message({
          type: "success",
          message: "复制成功!",
        });
        this.$router.push({
          name: "projectInviteList",
          params: {
            type: "internal",
          },
        });
      });
  }
}
